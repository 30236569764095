.questionnaire-container {
    margin: 20px;
}

.questionnaire-container h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    text-align: left;
}

.questionnaire-table {
    width: 100%;
    border-collapse: collapse;
}

.questionnaire-table th,
.questionnaire-table td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: center;
}

/* Uncomment this when returning to previous questions */
/* .questionnaire-table th:first-child,
.questionnaire-table td:first-child {
    text-align: left;
    width: 30%;
} */

.questionnaire-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

/* Uncomment this when returning to previous questions */
/* .questionnaire-table th:not(:first-child),
.questionnaire-table td:not(:first-child) {
    width: calc(70% / 7);
} */

/* Comment this when returning to previous questions. */
.questionnaire-table th,
.questionnaire-table td {
    width: calc(100% / 7);
}
.questionnaire-table input[type="radio"] {
    cursor: pointer;
    max-width: 20px;
    text-align: center;
    vertical-align: text-top;
}
.openedQuestions {
    display: flex;
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    bottom:0;
    background: white;
}
.openedQuestions .modal-dialog .modal-content {
    border: none;
    max-height: fit-content;
}
.openedQuestions .modal-dialog {
margin: 0;
    max-width: 100%;

    width: 100%;
}
.closedQuestions {
    display: none;
}
