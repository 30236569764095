.input {
  display: block;
  width: calc(100% - 40px);
  height: 54px;
  padding: 10px 14px;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 14px;
  outline: none;
  background-color: white;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input::placeholder {
  color: #999;
  font-size: 14px;
  opacity: 0.8;
}

.input:focus {
  border-color: #4264fd;
}